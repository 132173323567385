import axios from "@axios";
import { $themeConfig } from "@themeConfig";
////////////////////////////////////////////
const moduleName = "cv";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchItems(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        myApi
          .get(moduleName,
            {
              params: queryParams,
            }
          )
          .then((response) => resolve(response.data.data))
          .catch((error) => reject(error));
      });
    },
    fetchModuleCvs(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        myApi
          .get(moduleName,
            {
              params: queryParams,
            }
          )
          .then((response) => resolve(response.data.data))
          .catch((error) => reject(error));
      });
    },
    fetchModule(ctx, { id }) {
      return new Promise((resolve, reject) => {
        myApi
          .get(moduleName + `/${id}`
          )
          .then((response) => { 
            const data_to_update = response.data.data;
            resolve(data_to_update);
          })
          .catch((error) => reject(error));
      });
    },
    deleteModule(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
        .delete(moduleName + '/' + id)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
